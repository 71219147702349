<template>
  <v-navigation-drawer
    permanent
    right
    absolute
    touchless
    width="250"
    class="pa-4 grey--text"
  >
    <h3 class="display-1">{{$t('summary.title')}}</h3>
    <v-container>
      <template v-if="!error">
        <!-- ORDER LINES -->
        <v-layout row wrap class="my-2" v-for="orderLine in orderLines" :key="orderLine.name">
          <v-flex xs12 class="title">
            {{orderLine.name}}
          </v-flex>
          <v-flex xs2>
            x{{orderLine.quantity}}
          </v-flex>
          <v-flex xs10 class="text-right">
            <v-layout row wrap justify-end class="ma-0">
            <span v-if="localCurrency">
              {{formatAmount(orderLine.amount)}} {{localCurrency.name}}
            </span>
            <span v-if="foreignCurrency" class="font-weight-light ml-2">
              {{formatAmount(applyCurrencyExchange(orderLine.amount, foreignCurrency.id))}} {{foreignCurrency.name}}
            </span>
            </v-layout>
          </v-flex>
        </v-layout>
        <!-- TOTAL -->
        <v-layout v-if="orderLines.length" row wrap class="my-3 text-right title">
          <v-flex xs3>
            {{$t('summary.total')}}
          </v-flex>
          <v-flex v-if="localCurrency" xs9>
            {{formatAmount(orderTotal)}} {{localCurrency.name}}
          </v-flex>
          <v-flex v-if="foreignCurrency" xs12 class="font-weight-light">
            {{formatAmount(applyCurrencyExchange(orderTotal, foreignCurrency.id))}} {{foreignCurrency.name}}
          </v-flex>
        </v-layout>
        <v-layout v-else row wrap class="my-3 title font-weight-light">
          <v-flex xs12>
            {{$t('summary.empty_order')}}
          </v-flex>
        </v-layout>
        <!-- CURRENCY -->
        <v-layout row wrap>
          <v-flex xs12 class="my-3">
            <v-flex xs12 class="caption mb-2">
              {{$t('fields.currency')}}
            </v-flex>
            <v-btn-toggle
              v-model="currency"
              mandatory
              dense
              color="primary"
              background-color="transparent"
              borderless
            >
              <v-btn v-for="currency in currencies" :key="currency.id" :value="currency.id">
                {{currency.name}}
              </v-btn>
            </v-btn-toggle>
          </v-flex>
          <!-- PAYMENT METHOD -->
          <v-flex xs12 class="my-3">
            <v-flex xs12 class="caption mb-2">
              {{$t('fields.payment_method')}}
            </v-flex>
            <payment-method-toggle
              :value="paymentMethod"
              :excludedPaymentMethodIds="excludedPaymentMethodIds"
              @change="(v) => paymentMethod = v"
              :includeRoomCharge="storeModule === 'packageOrder' && isHotelGuest(guest)"
            />
          </v-flex>
        </v-layout>
      </template>
      <v-layout v-else column class="pt-4">
        <!-- ERRORS -->
        <v-layout v-if="dailyLimitExceedingPackages.length" justify-end class="mt-3 flex-none">
          <v-alert color="error" dark>
            {{ `${$t('packages.warnings.daily_limit_exceeding_packages')} ${dailyLimitExceedingPackages.map(id => getPackageName(id)).join(', ')}.` }}
          </v-alert>
        </v-layout>
        <v-layout v-if="accountLimitExceedingPackages.length" justify-end class="mt-3 flex-none">
          <v-alert color="error" dark>
            {{ `${$t('packages.warnings.account_limit_exceeding_packages')} ${accountLimitExceedingPackages.map(p => getPackageName(p.id)).join(', ')}.` }}
          </v-alert>
        </v-layout>
      </v-layout>
      <!-- ACTIONS -->
      <v-layout row wrap justify-end>
        <v-btn color="primary" class="my-2" @click="$emit('continue')" :disabled="!enableContinueButton || error" :loading="state.loading || loading">
          {{$t('summary.actions.continue')}}
        </v-btn>
        <v-btn color="primary" outlined @click="$emit('cancel')" :disabled="!enableCancelButton">
          {{$t('summary.actions.cancel')}}
        </v-btn>
      </v-layout>
    </v-container>
  </v-navigation-drawer>
</template>
<script>
import moneyFormat from '@/mixins/MoneyFormat'
import localizationFormat from '@/mixins/Localization'
import pricingHelpers from '@/mixins/PricingHelpers'
import { mapState, mapGetters } from 'vuex'
import { roomChargePaymentMethod } from '@/config'
import { isHotelGuest, getTokensInAccount } from '@/utils/GuestUtils'
export default {
  // EMITTED EVENTS: continue, cancel
  props: {
    orderLines: Array,
    // orderLines: [{ name, quantity, amount }], estando amount en la baseCurrency
    storeModule: String,
    // storeModule: "ticketOrder"
    enableContinueButton: Boolean,
    loading: Boolean,
    enableCancelButton: Boolean,
    excludedPaymentMethodIds: {
      type: Array,
      default: function () {
        return []
      }
    },
    extendedAccount: Object,
    includeRoomCharge: Boolean,
    extendedAccount: Object
  },
  data () {
    return {
      isHotelGuest
    }
  },
  created () {
    this.$store.dispatch('paymentMethods/list').catch(() => {})
  },
  computed: {
    ...mapState({
      facility: state => state.facilities.read,
      state: state => state.state,
      dailyLimitExceedingPackages: state => state.packageOrder.bookingErrorPackages,
      guest: state => state.guests.read
    }),
    ...mapGetters({
      currencies: 'configuration/currencies',
      localCurrency: 'configuration/localCurrency',
      foreignCurrency: 'configuration/foreignCurrency',
      paymentMethods: 'configuration/paymentMethods',
      getPackageField: 'packages/getPackageField',
      getPackageAmount: 'packageOrder/getPackageAmount',
      isAccountLimitExceeded: 'packageOrder/isAccountLimitExceeded'
    }),
    currency: {
      get () {
        return this.$store.state[this.storeModule].currencyId
      },
      set (value) {
        this.$store.commit(`${this.storeModule}/setCurrencyId`, value)
      }
    },
    paymentMethod: {
      get () {
        return this.$store.state[this.storeModule].paymentMethodId
      },
      set (value) {
        const v = typeof value === 'number' ? null : value
        this.$store.commit(`${this.storeModule}/setPaymentMethodId`, v)
      }
    },
    orderTotal () {
      var total = 0
      this.orderLines.forEach(orderLine => {
        total += orderLine.amount * orderLine.quantity
      })
      return total
    },
    error () {
      return this.storeModule === 'packageOrder' && !!(this.dailyLimitExceedingPackages.length || this.accountLimitExceedingPackages.length)
    },
    accountLimitExceedingPackages () {
      return this.$store.state.packages.list
        .filter(pkg => {
          return this.storeModule === 'packageOrder' && this.isAccountLimitExceeded({ pkg, curr: this.getCurrentAmount(pkg) })
        })
    },
    accountCurrencies () {
      return this.extendedAccount ? this.extendedAccount.currencies : []
    }
  },
  watch: {
    orderTotal () {
      this.$store.commit(`${this.storeModule}/setTotalAmount`, this.orderTotal)
    },
    filteredPaymentMethods (list) {
      if (!this.paymentMethod) {
        this.paymentMethod = list[0] ? list[0].id : null
      }
    }
  },
  methods:  {
    getPackageName (id) {
      return this.localize({ array: this.getPackageField({ id, field: 'name' }) })
    },
    getCurrentAmount (pkg) {
      return parseInt(getTokensInAccount(this.accountCurrencies, pkg) / pkg.tokenAmount)
    }
  },
  components: {
    PaymentMethodToggle: () => import('@/components/view-components/PaymentMethodToggle.vue')
  },
  mixins: [
    moneyFormat,
    pricingHelpers,
    localizationFormat
  ]
}
</script>
